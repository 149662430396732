@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    /* BRAND COLORS */

    --primary: hsl(90, 28%, 60%);
    --primary-light: hsl(90, 28%, 68%);
    --primary-dark: hsl(70, 37%, 31%);
    --primary-foreground: var(--white);

    --secondary: hsl(24, 56%, 56%);
    --secondary-light: hsl(26, 54%, 97%);
    --secondary-dark: hsl(25, 44%, 39%);
    --secondary-foreground: var(--white);

    /* UTIL COLORS */

    --warning: hsl(36, 100%, 57%);
    --warning-light: hsl(37, 79%, 81%);
    --warning-dark: hsl(30, 100%, 48%);
    --warning-foreground: var(--white);

    --info: hsl(199, 92%, 56%);
    --info-light: hsl(199, 89%, 85%);
    --info-dark: hsl(227, 80%, 55%);
    --info-foreground: var(--white);

    --success: hsl(123, 38%, 57%);
    --success-light: hsl(145, 81%, 96%);
    --success-dark: hsl(123, 43%, 39%);
    --success-foreground: var(--white);

    --error: hsl(4, 90%, 58%);
    --error-light: hsl(5, 86%, 97%);
    --error-dark: hsl(0, 65%, 51%);
    --error-foreground: var(--white);

    /* NEUTRAL COLORS */

    --black: hsl(220, 43%, 11%);
    --white: hsl(0, 0%, 94%);

    --background: hsl(0, 0%, 100%);
    --background-paper: hsl(0, 0%, 94%);
    --background-neutral: hsl(210, 22%, 96%);

    --foreground: hsl(240, 10%, 4%);

    /* COMPONENTS */

    --card: hsl(0, 0%, 100%);
    --card-foreground: hsl(240, 10%, 4%);
    --popover: hsl(0, 0%, 100%);
    --popover-foreground: hsl(240, 10%, 4%);
    --muted: hsl(240, 5%, 96%);
    --muted-foreground: hsl(240, 4%, 46%);
    --accent: hsl(210, 22%, 96%);
    --accent-foreground: hsl(0, 0%, 41%);
    --border: hsl(240, 6%, 90%);
    --border-light: hsl(210, 100%, 34%);
    --dialog: hsla(0, 0%, 0%, 0.49);
    /*--border-light: hsl(210, 22%, 96%);*/
    --input: hsl(240, 6%, 90%);
    --ring: hsl(240, 6%, 10%);
    --radius: 0.5rem;

    --chart-1: #e76e50;
    --chart-2: #2a9d90;
    --chart-3: #274754;
    --chart-4: #e8c468;
    --chart-5: #f4a462;

    --rdp-accent-color: var(--primary)
  }
}

@layer utilities {
  /* TYPOGRAPHY */
  .heading-1 {
    @apply text-3xl font-extrabold text-black;
  }
  .heading-2 {
    @apply text-2xl font-bold text-black;
  }
  .heading-3 {
    @apply text-xl font-semibold text-black;
  }
  .heading-4 {
    @apply text-lg font-semibold text-black;
  }
  .heading-5 {
    @apply text-lg font-medium text-black;
  }
  .heading-6 {
    @apply text-lg font-normal text-black;
  }

  .body-1 {
    @apply text-lg font-medium text-black;
  }
  .body-2 {
    @apply text-md font-normal text-black;
  }
  .body-3 {
    @apply text-sm font-normal text-black;
  }
  .body-4 {
    @apply text-xs font-light text-black;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}